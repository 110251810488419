import { Link } from 'gatsby'
import React, { useEffect, useState } from 'react'
import ReactPlayer from 'react-player'
import Menu from '../components/Menu'
import Seo from '../components/Seo'
import { useLangContext } from '../context/lang.context'
import { sButtonSmall } from '../style'

const video = 'https://youtu.be/1FEc0Tg4qOE&rel=0'

const seo = {
  title: {
    en: 'Campaign Video',
  },
  desc: {
    en: '',
  },
}

const content = {
  en: 'Back to home',
}

const Video = () => {
  const { lang } = useLangContext()

  return (
    <>
      <Seo title={seo.title[lang]} desc={seo.desc[lang]} noIndex />
      <div css={sPlayerFullscreen}>
        <ReactPlayer
          controls
          playing
          url={video}
          width='100%'
          height='100%'
          playsinline
        />
        <Link
          to='/'
          css={[
            {
              position: 'absolute',
              top: '2rem',
              left: '50%',
              transform: 'translateX(-50%)',
              zIndex: 2,
            },
            sButtonSmall,
          ]}
        >
          {content[lang]}
        </Link>
      </div>
      <Menu />
    </>
  )
}

const sPlayerFullscreen = {
  position: 'fixed',
  top: 0,
  left: 0,
  zIndex: 100,
  width: '100%',
  height: '100%',
}

export default Video
